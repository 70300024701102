/*
  * The Stride dApp is in light mode, so we will update .leap-ui declaration
  */
.leap-ui.stride {
  /*
     * Stride primary color is #E50571
     * Convert it to HSL - hsl(331deg, 95.7%, 45.9%)
     * We set the --primary variable to 331 95.7% 45.9%
     */
  --primary: 331 95.7% 45.9%;
  /*
     * Primary Foreground color - white
     */
  --primary-foreground: 0 0% 100%;
  /*
     * Ring color - hsl(323deg, 94.2%, 59.2%)
     */
  --ring: 323 94.2% 59.2%;
  /*
     * Background color - white
     */
  --background: 0 0% 100%;
  /*
     * Foreground color - hsl(331deg, 100%, 11.8%)
     */
  --foreground: 331 100% 11.8%;
  /*
     * Secondary Background - hsl(330deg, 16.7%, 86.3%)
     */
  /*--secondary: 330 16.7% 86.3%;*/
  --secondary: 330 16.7% 97.6%;
  /*
     * Secondary Foreground - hsl(331deg, 100%, 11.8%)
     */
  --secondary-foreground: 331 100% 11.8%;
  /*
     * Popover Background color - hsl(330deg, 5%, 98%)
     */
  --popover: 0 0% 100%;
  /*
     * Popover Foreground color - hsl(331deg, 100%, 11.8%)
     */
  --popover-foreground: 330 10% 23%;
  /*
     * Card Background - hsl(330deg, 12%, 95.3%)
     */
  --card: 330 12% 95.3%;
  /*
     * Card Foreground - hsl(331deg, 100%, 11.8%)
     */
  --card-foreground: 331 100% 11.8%;
  /*
     * Accent color - hsl(292deg, 20%, 91%)
     */
  --accent: 292 20% 91%;
  /*
     * Accent Foreground - hsl(331deg, 100%, 11.8%)
     */
  --accent-foreground: 331 100% 11.8%;
  /*
     * Muted Background color - hsl(330deg, 0%, 95%)
     */
  --muted: 0 0% 90%;
  /*
     * Muted Foreground color - hsl(329deg, 11.7%, 38.6%)
     */
  --muted-foreground: 329 11.7% 38.6%;
  /*
     * Border - hsl(330deg, 13.8%, 88.6%)
     */
  --border: 330 13.8 88.6;
  /*
     * Input color - hsl(330deg, 13.8%, 88.6%)
     */
  --input: 330 13.8% 88.6%;
  /*
     * Base border radius - 0.75rem
     */
  --radius: 0.75rem;
  /**
     * Destructive background - hsl(0deg, 85.6%, 59.2%)
     */
  --destructive: 0 85.6% 59.2%;
  /**
     * Destructive foreground - hsl(331deg, 100%, 11.8%)
     */
  --destructive-foreground: 331 100% 11.8%;
  /**
     * Warning background - hsl(36.2deg, 100%, 42%)
     */
  --warning: 36.2 100% 42%;
  /**
     * Warning foreground - hsl(331deg, 100%, 11.8%)
     */
  --warning-foreground: 331 100% 11.8%;
  /**
     * Success foreground - hsl(331deg, 100%, 11.8%)
     */
  --success-foreground: 331 100% 11.8%;
}

.leap-ui.stride {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif,
    Apple Color Emoji, Segoe UI Emoji;
  /* Fixes issue with Slippage layout being out of whack due to Mantine setting some weird defaults */
  word-break: initial;
}

.leap-ui.stride :is(.backdrop-blur-overlay) {
  /* Fixes backdrop being darker on the modals supposed corners */
  border-radius: 8px;
}

.leap-ui.stride [role='dialog'] {
  /* Hotfix for weird positioning */
  top: 25% !important;
  /* This is a temporary fix for the modal position. Given this is a hard-set to `transform`, it also removes all of the transitions provided out of the box */
  transform: translateX(-50%) !important;
}

/* .leap-ui.stride.dark {
  --primary: 331 95.7% 45.9%;
  --primary-foreground: 0 0% 100%;
  --ring: 323 94.2% 59.2%;
  --background: 331 100% 11.8%;
  --foreground: 0 0% 100%;
  --destructive: 0 85.6% 59.2%;
  --destructive-foreground: 331 100% 11.8%;
  --warning: 36.2 100% 42%;
  --warning-foreground: 331 100% 11.8%;
  --success-foreground: 331 100% 11.8%;
} */
